import { debounce } from 'lodash';
import React, { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { checkIfUrlIncludes } from 'Util/Url';

import ProductBuiltListSlider from './ProductBuiltListSlider.component';

/** @namespace Pwa/Component/ProductBuiltListSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ProductBuiltListSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ProductBuiltListSlider/Container/ProductBuiltListSliderContainer */
export class ProductBuiltListSliderContainer extends PureComponent {
    swiperRef = createRef();

    state = {
        currentSlide: 1,
        isHover: false,
    };

    handleHoverDisable = debounce(this._handleHoverDisable.bind(this), 500);

    containerFunctions = {
        handleHoverEnable: this.handleHoverEnable.bind(this),
        handleHoverDisable: this.handleHoverDisable,
        handleNext: this.handleNext.bind(this),
        handlePrevious: this.handlePrevious.bind(this),
        handleSlideChange: this.handleSlideChange.bind(this),
        setSwiperRef: this.setSwiperRef.bind(this),
    };

    setSwiperRef(element) {
        this.swiperRef.current = element;
    }

    handleHoverEnable() {
        this.setState(
            {
                isHover: true,
            },
            () => this.handleHoverDisable.cancel()
        );
    }

    _handleHoverDisable() {
        this.setState({
            isHover: false,
        });
    }

    handleNext() {
        if (this.swiperRef.current && this.swiperRef.current.swiper) {
            this.swiperRef.current.swiper.slideNext();
        }
    }

    handlePrevious() {
        if (this.swiperRef.current && this.swiperRef.current.swiper) {
            this.swiperRef.current.swiper.slidePrev();
        }
    }

    handleSlideChange() {
        if (this.swiperRef.current && this.swiperRef.current.swiper) {
            this.setState({
                currentSlide: this.swiperRef.current.swiper.realIndex + 1,
            });
        }
    }

    containerProps() {
        const { products, title, device } = this.props;
        const { currentSlide, isHover } = this.state;

        const isBlog = checkIfUrlIncludes('blog');

        return {
            title,
            products,
            device,
            currentSlide,
            isHover,
            isBlog,
        };
    }

    render() {
        return <ProductBuiltListSlider {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBuiltListSliderContainer);
