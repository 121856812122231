import React, { PureComponent } from 'react';
import Swiper from 'react-id-swiper';

import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductPrice from 'Component/ProductPrice';
import { noopFn } from 'Util/Common';
import { getPrice } from 'Util/Product/Extract';

import './ProductBuiltListSlider.style';

/** @namespace Pwa/Component/ProductBuiltListSlider/Component/ProductBuiltListSlider */
export class ProductBuiltListSlider extends PureComponent {
    renderHeader() {
        const { title } = this.props;
        return <h4>{title}</h4>;
    }

    renderSideNavigation({
        isHover = false,
        current = 1,
        totals = 1,
        onHoverEnable = noopFn,
        onHoverDisable = noopFn,
        onPrevious = noopFn,
        onNext = noopFn,
    }) {
        if (!isHover) {
            return null;
        }

        return (
            <>
                {current > 1 ? (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                        <button
                            block="ProductBuiltListSlider"
                            elem="SideNavigation-Previous"
                            onMouseOver={onHoverEnable}
                            onMouseLeave={onHoverDisable}
                            onClick={onPrevious}
                        >
                            <Icons name="arrow_left" />
                        </button>
                    </>
                ) : null}
                {current < totals ? (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                        <button
                            block="ProductBuiltListSlider"
                            elem="SideNavigation-Next"
                            onMouseOver={onHoverEnable}
                            onMouseLeave={onHoverDisable}
                            onClick={onNext}
                        >
                            <Icons name="arrow_right" />
                        </button>
                    </>
                ) : null}
            </>
        );
    }

    renderProduct({ uid, thumbnail = {}, name, price_range, dynamic_price, type_id, url }) {
        return (
            <Link to={url} block="ProductBuiltListSlider" elem="Product" key={uid}>
                <Image
                    mix={{ block: 'ProductBuiltListSlider', elem: 'Product-Image' }}
                    src={thumbnail?.url}
                    alt={name}
                />
                <div block="ProductBuiltListSlider" elem="Product-Content">
                    <ProductPrice
                        mix={{ block: 'ProductBuiltListSlider', elem: '-Price' }}
                        price={getPrice(price_range, dynamic_price, {}, type_id)}
                    />
                    <h3 block="ProductBuiltListSlider" elem="Product-Name">
                        {name}
                    </h3>
                </div>
            </Link>
        );
    }

    render() {
        const {
            products,
            isHover,
            currentSlide,
            handleHoverDisable,
            handleHoverEnable,
            handlePrevious,
            handleNext,
            handleSlideChange,
            setSwiperRef,
            isBlog,
        } = this.props;

        const slider = {
            slidesPerView: 1,
            spaceBetween: 16,
            on: {
                slideChange: handleSlideChange,
            },
            breakpoints: {
                1280: {
                    spaceBetween: 32,
                    slidesPerView: isBlog ? 2 : 4,
                },
                1024: {
                    spaceBetween: 32,
                    slidesPerView: isBlog ? 2 : 3,
                },
                767: {
                    spaceBetween: 16,
                    slidesPerView: 2,
                },
            },
        };

        return (
            <div block="ProductBuiltListSlider" elem="Wrapper">
                {this.renderHeader()}
                {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                <div block="ProductBuiltListSlider" onMouseOver={handleHoverEnable} onMouseLeave={handleHoverDisable}>
                    {this.renderSideNavigation({
                        isHover,
                        totals: products?.length,
                        current: currentSlide,
                        onPrevious: handlePrevious,
                        onNext: handleNext,
                    })}
                    <Swiper {...slider} ref={setSwiperRef}>
                        {products.map(this.renderProduct.bind(this))}
                    </Swiper>
                </div>
            </div>
        );
    }
}

export default ProductBuiltListSlider;
